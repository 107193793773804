export const enLanguage = {
	close: 'Close',
	refresh: 'Refresh',
	dataIterator: {
		noResultsText: 'No matching records found',
		loadingText: 'Loading items...',
	},
	dataTable: {
		itemsPerPageText: 'Rows per page:',
		ariaLabel: {
			sortDescending: ': Sorted descending. Activate to remove sorting.',
			sortAscending: ': Sorted ascending. Activate to sort descending.',
			sortNone: ': Not sorted. Activate to sort ascending.',
		},
		sortBy: 'Sort by',
	},
	dataFooter: {
		itemsPerPageText: 'Items per page:',
		itemsPerPageAll: 'All',
		nextPage: 'Next page',
		prevPage: 'Previous page',
		firstPage: 'First page',
		lastPage: 'Last page',
		pageText: '{0}-{1} of {2}',
	},
	datePicker: {
		itemsSelected: '{0} selected',
	},
	noDataText: 'No data available',
	carousel: {
		prev: 'Previous visual',
		next: 'Next visual',
		ariaLabel: {
			delimiter: 'Carousel slide {0} of {1}',
		},
	},
	calendar: {
		moreEvents: '{0} more',
	},
	fileInput: {
		counter: '{0} files',
		counterSize: '{0} files ({1} in total)',
	},
	timePicker: {
		am: 'AM',
		pm: 'PM',
	},
	filter:{
		setting: 'Sidebar Settings',
		image:'Background',
		doc: 'Document',
		thank: 'Thank you for using!'
	},
	common: {
		project: 'ICLOUD',
		page: 'page',
		bindBtn:'Bind',
		editBtn:'Edit',
		deleteBtn: 'Delete',
		noData: 'No Data',
		action: 'Action',
		sureBtn: 'Sure',
		returnBtn: 'Return',
		cancelBtn: 'Cancel',
		saveBtn: 'Save',
		sureLabel: 'OK',
		cancalLabel: 'Cancel',
		tip: 'Tips',
		loginOut: 'Logout',
		login: 'Login',
		register: 'Register',
		role: 'No Role',
		close: 'Close',
		next: 'Next',
		pre: 'Previous',
		pass: 'Pass',
		pickTimeRange: 'Select time period',
		export: 'Export',
		loading:'Loading',
		reload:'Restart',
		info: 'Look up',
		fileInput: 'Upload',
		search:'Search',
		look:'Look',
		config: 'Config',
		change: 'Change',
		rePassword: 'Change Password',
		logout: 'Logout',
		login: 'Login',
		configlogin: 'EditConfig',
		initdb:'Init Data Base',
		confirmNewPassword: 'Confirm new password',
		newPassword: 'New password',
		oldPassword: 'Input password',
		flowUnit: 'FlowUnit（TB）',
		people: 'people',
		noresq: 'Return error,Contact administrator',
		errresp: 'Return error',
		flowdw: '',
		flowcnt: '',
		success:'Sucess',
		errors:'Fail',
		edit: 'Edit',
		add: 'Add',
		confirmdel:'Confirm deletion',
        input: 'Cannot be empty',
        del_api_warning: 'This operation may delete all data about this SDK!',
        del_gateway_warning: 'This operation may delete all data about this gateway!',
        formNull: 'Cannot be empty,Please fill in the correct format',
		formatmac: 'Format error',
		offline: 'Offline',
        reset:'Reset'
	},
	menu: {
		dashboard: 'Dashboard',
		search:'Search',
	},
	login: {
		nullResponse: '返回为空',
		nullTip: 'Username or password is required',
		errTip: 'Wrong user name or password',
		forget: 'Forgot your password?',
		input: 'Please enter user name and account number',
		person: 'Personal Center',
		info: 'Personal information',
		edit: 'Editing',
		edit_txt: 'Improve your profile',
		modifypwdSucc: 'Password changed successfully',
		logoutSucccess: 'Logout Succcess',
		modifypwdErr: 'Password modification failed',
		confirmNot: 'The two password inputs are inconsistent',
		confirmNullInput: 'Password cannot be empty'
	},
	bindPermission: {
		role: 'Add roles',
		permission: 'Binding authority',
		account: 'Add account',
		building: 'Add group',
		set: 'Configure users',
	},
	shop: {
		warning: 'Only one group can be selected for binding',
		table: {
			theme: 'List of stores',
			id: 'ID',
			name: 'Name',
			province: 'Province',
			city: 'City',
			area: 'Area',
			address: 'Address',
			created_at: 'Created_at',
			updated_at: 'Updated_at',
		},
		update: 'Update stores',
		add: 'Add stores',
		rule: {
			name: 'Name is required'
		},
		deleteTip: 'Whether to delete the store, Once deleted, it cannot be recovered!'
	},
	building: {
		deleteTheme: 'Deleted group list',
		table: {
			theme: 'Group list',
			id: 'ID',
			name: 'Name',
			desc: 'description',
			created_at: 'created_at',
			updated_at: 'updated_at',
		},
		update: 'Update group',
		add: 'Add group',
		rule: {
			name: 'Name is required'
		},
		deleteTip: "Are you sure to delete the group? Once deleted, it cannot be recovered!",
		warning: 'Group information is required',
	},
	permission: {
		deleteTheme: 'Permission list deleted',
		add: 'Add permission',
		table: {
			theme: 'Permission list',
			id: 'ID',
			name: 'Route',
			zh: 'Name',
			icon: 'Icon',
			is_menu: 'Menu',
			created_at: 'Created_at',
			updated_at: 'Updated_at',
			status: 'Status',
			height: 'Height',
			pid: 'Subordinate ID',
			description: 'description',
		},
		rule: {
			name: 'Route is required',
			zh: 'Name is required',
			icon: 'Icon is required',
			pid: 'Dependent ID is required',
			height: 'Height is required',
			menu: 'Menu item is required',
		},
		deleteTip: "Are you sure you want to delete this permission!",
		deleteTipForce: 'Are you sure you want to delete this permission, Once it is deleted, it cannot be restored?',
		deleteTipStore: 'Are you sure you want to restore this permission?',
	},
	user: {
		user: 'User',
		userTip: 'Improve role information',
		userTipAdd: 'Add role information',
		buttonAdd: 'Add user',
		buttonUpdate: 'Modify user',
		buttonUse: 'Is it enabled?',
		theme: 'Soft delete user list',
		table: {
			theme: 'User list',
			name: 'Username',
			uuid: 'Uuid',
			nickname: 'Nickname',
			password: 'Password',
			repassword: 'Repeat password',
			rememberuser: 'Remember Username',
			phone: 'Phone',
			email: 'Email',
			create_at: 'created_at',
			update_at: 'updated_at',
			delete_at: 'deleted_at',
			info: 'Other information',
			tip: '(Modifiable)',
			tip1: '(Must fill)'
		},
		rule: {
			name: 'Category name is required',
			nickname: 'Nickname is required',
			password: 'Password is required',
			phone: 'Phone is required',
			email: 'Email is required',
			info: 'Information is required',
		},
		tips: 'Please bind the user',
		permissionRole: 'All role types',
		permissionBuilding: 'All building types',
		roleTip: 'Please select a role to bind. It is required',
		buildingTip: 'Please select a building for binding, is required',
		warningTip: 'Role or building is required',
		deleteTip: 'Are you sure you want to delete this user',
		deleteTipForce: 'Are you sure you want to delete this user. Once deleted, it cannot be recovered?',
		deleteTipStore: 'Are you sure you want to restore this user?',
		warning: 'User information is required',
	},
	role: {
		txt: 'all',
		userTo: 'Configuration permission Click ',
		theme: 'Soft delete role list',
		peimission: 'Peimissions',
		permission_txt: 'Please select your permission',
		table: {
			theme: 'Role list',
			name: 'Role Name',
			type: 'Type',
			description: 'Description',
			create_at: 'Create Time',
			update_at: 'Update Time',
			delete_at: 'Delete Time',
			action: 'Action',
			info: 'Info',
			tip: '(Modifiable)',
			tip1: '(Required)'
		},
		rule: {
			type: 'Type is required',
			name: 'Name is required',
			descript: 'Descriptions is required',
			info: 'Info is required'
		},
		role: 'Role',
		roleTip: 'Improve role information',
		roleTipAdd: 'Add role information',
		buttonAdd: 'Add',
		buttonUpdate: 'Update',
		buttonDelete: 'Delete',
		buttonLock: 'Lock',
		tips: 'Please select permissions for this role',
		deleteTip: 'Are you sure you want to delete this user',
		deleteTipForce: 'Are you sure you want to delete this role. Once deleted, it cannot be recovered?',
		deleteTipStore: 'Are you sure you want to restore this role?',
		warning: 'Role information is required',
	},
	dashboard:{
		firstTitle1: 'Online|Total',
		firstTitle2: 'Current online number',
		firstTitle3: 'Accumulated today',
		firstTitle4: 'Cumulative users',
		firstText1: 'Gateway',
		firstText2: 'Online number',
		firstText3: 'Number of certifications',
		firstText4: 'Total users',
		inOutFlowTitle: 'Uplink/Downlink',
		inOutFlow: 'Flow',
		connectionTitle: 'Connection number/TCP/UDP/ICMP',
		connection: 'Connection number',
		nowTitle: 'Now/Peak value',
		now: 'Now',
		ppsTitle: 'PPS/SYN/SYN ACK',
		pps: 'PPS',
		rest: 'Surplus',
		secondLineTitle: 'authentication diagram',
		secondLineTitle1: 'Flow distribution',
		secondLineTitle2: 'Connection distribution',
		secondLineTitle3: 'Flow overview',
		fifthLineTitle: 'Connection trend',
		new: 'New user',
		old: 'Old user',
		all: 'All user',
		thirdLineTitle: 'User model',
		myChart3_1_title: 'Proportion of application visits',
		myChart3_2_title: 'User residence time distribution',
		myChart3_3_title: 'Proportion of user residence time',
		myChart3_4_title: 'Proportion of terminal type',
		myChart3_5_title: 'Terminal brand top5',
		thirdLineTitle: 'User model',
		myChart4_1_title: 'Flow time distribution',
		myChart4_2_title: 'Application flow time distribution',
		myChart4_3_title: 'Proportion of authentication methods',
		myChart4_4_title: 'Proportion of terminal platform traffic',
		myChart4_5_title: 'Application frequency ratio top5',
		forthLineTitle: 'Flow model',
		fifthLineTitle: 'Upstream traffic trend',
		sixthLineTitle: 'Downstream traffic trend',
		seventhLineTitle: 'Connection trend',
	},
	portalMac:{
		pick_placename: 'Select a place',
		pick_auth_name: 'Select a auth name',
		pick_type_name: 'Select a auth type',
		pick_status: 'Select status',
		input_phone: 'Input phone',
		input_mac: 'Input mac',
		input_ip: 'Input ip',
		selectTitle: "Query criteria",
		type: 'Auth type',
		tableName: "Auth list",
		visitorTableName: "Visitor list",
        identity: "Identity",
        type_name: "Auth type",
        auth_name: "auth name",
        upflow: "Upflow",
        downflow: "Downflow",
        usetime: "Online time",
        status: "Online status",
        created_at: "Auth time",
        updated_at: "Update time",
		pickjt: 'Select a group',
		jt: 'group',
		id: 'ID',
		ip: 'IP',
		mac: 'mac',
		in_flow: 'Upflow',
		out_flow: 'Downflow',
		flowcnt: 'Number of connections',
		inrate: 'Upstream rate',
		outrate: 'Downstream rate',
		name: 'user name',
		day: 'time',
		device: 'Device',
		device_id: 'Device ID',
		device_name: 'Device name',
		placeName: 'Place name',
		authDate: 'aAth Date',
		upLimit: 'Upstream rate limit',
		downLimit: 'Downstream rate limit',
		ableDate: 'term of validity',
		aliveLastTime: 'Last active time',
		action: 'Action',
		editItem: 'Edit',
		chartTitle: 'Trend chart of auth personnel',
		personas: 'User model',
		pickWg: 'Select s gateway',
		phone: 'phone',
		authzone_id: 'Authzone Id',
		sdkVersion: 'SDK Version',
		ua: 'UA',
		browser: 'Browser',
		platform: 'Platform',
		online: "Online",
		offline: "Offline",
		input_username: 'Input username',
		username: 'Username'
	},
	appfilter: {
		showinfo:"App",
		tableName: 'User List',
		hostname: 'Host Name',
		mac: 'mac',
		ip: 'ip',
		applist: 'APP List',
		updated_at: 'Update Time',

		app_class_visit_time:"App Class Visit Time",
		dev_visit_time:"Dev Visit Time",
		dev_visit_list:"Dev Visit List",
		appname:"App Nme",
		total_time:"Total Time",
		first_time:"Start Time",
		latest_action:"Action",
		filterate:"Filterate",
		unfilterate:"Unfilterate"
	},
	bandwidth:{
		tableName: "Bandwidth list",
		wg: 'Gateway',
		date: 'Date',
		action: 'Action',
		editItem: 'Edit',
		chartTitle: 'Trend map',
		chartTitle1: 'Up trend',
		chartTitle2: 'Down trend',
		chartTitle3: 'Time trend',
		pickWg: 'Select gateway',
		pickTimeStart: 'Select a start time',
		pickTimeEnd: 'Select a end time',
	},
	AppIdent: {
		tableName: "APP list",
		name: 'Agreement Name',
		connection: '连接数',
		up: 'Up',
		down: 'Down',
		d_up: 'Proxy up',
		d_down: 'Proxy down',
		count_flow: 'Cumulative flow',
		ten_flow: 'Ten minute flow',
		device_name: 'Device name',
		day: 'Day',
		hour: 'Hour'
	},
	personas: {
		personas: 'User model',
		phone: 'Phone',
		mac: 'MAC',
		ua: 'user agent',
		authzone_name: 'Authzone name',
		ip: 'IP',
		created_at: 'Auth time',
		chartTitle: 'APP Show',
	},
	server:{
		titleServer:'Server management',
		title:'Authentication library information',
		titleBase:'Authentication library',
		titleDeleteAttribute:'Delete attribute',
		titleAddAttribute:'Add attribute',
		wechatAdmin:{
			title:"QR Code Authorizer",
			add:"Add Authorizer",
			openid:"Openid",
			remark:"Remark",
			create_at:"Create time"
		},
		visitor:{
			title:"Visitor list",
			add:"Add visitor",
			create_at:"Create time",
			name:"Visitor's name",
			telnum:"Visitor's telnum",
			mail:"Visitor's mail",
			company:"Visitor's company",
			interviewee_name:"Interviewee's name"
		},
		bevisitor:{
			title:"Interviewee List",
			add:"Add Visitees",
			create_at:"Create time",
			name:"Interviewee's name",
			telnum:"Interviewee's telnum",
			mail:"Interviewee's mail",
			company:"Interviewee's company",
			interviewee_name:"Visitor's name"
		},
		template:{
			download:'Download',
			import:'Import',
			filechoose:'File Selection'
		},
		table:{
			addServer:'Add radius',
			add:'Add authentication',
			addBase:'Add certification Library',
			name:'Name of certification base',
			sessionTime:'Length of Internet access(s)',
			idleTime:'Free online time',
			filterId:'User group or ACLID',
			aruba:'Role definition',
			password:'Password',
			username:'Username',
			created_at:'Creation time',
			updated_at:'Update time',
			radiusName:'Name',
			radiusAddress:'Address',
			radiusStatus:'Status',
			radiusPort1:'Charging Por',
			radiusPort2:'Accounting Port',
			tempclose:'Temporarily Closed',
			remarks:'remarks',
			disable_at:'Pause Time',
			expire_at:'Expiration Time',
			created_at:'Create time',
			aruba_xml_key:'aruba xml key',
			default_role:'Default Role'
		},
		group:{
			title: "Auth Group",
			name: "Group name",
			add: "Add Group",
			editTitle: "Edit Group",
            savemonth: "Save Monuth"
		},
		btn:{
			addRadius:'Add radius address',
			add:'Add authentication',
			edit:'Edit authentication',
			delete:'Confirm deletion',
			addBase:'Add authentication library',
			editBase:'Edit authentication library',
			restart:'Restart',
			save:'Save',
			close:'Close',
			open:'Open',
		},
		label:{
			baselist:'Authentication library list',
			base:'Please select authentication library',
			username:'Username',
			password:'Password',
			charging:'Radius Charging Port',
			accounting:'Radius Accounting Port',
			name:'Name',
			sessionTime:'Length of Internet access(s)',
			idleTime:'Free online time',
			filterId:'User group or ACLID',
			aruba:'Role definition',
			port:'Please Input the port number',
			attr:'Radius attribute',
			attrData:'Radius data',
			groupName:'Group name'
		},
		dialogs:{
			delete:'Confirm to delete information',
			titleDeleteAttribute:'Confirm to delete attribute',
			save:'Save',
			restart:'Restart',
			open:'Open',
			close:'Close',
		},
		rule:{
			name:'Name is required',
			username:'Username is required',
			password:'Password is required',
			radiusName:'Radius name is required',
			radiusAddress:'Radius address is required',
			port:'Port is required',
			shop:'Shop is required',
			group:'Group is required',
			attr:'Attribute is required',
			attrValue:'Attribute value  is required',
			groupname:'Group name is required',
			remark:'Remark is required',
			remarkLimit:'Enter up to 8 characters',
            authType:'auth Type is required',
            ldap:'Ldap is required'
		},
		placeholder:{
			name:'Please Input the authentication library name',
			radiusAddress:'Please Input the radius address',
			radiusName:'Please Input the radius name',
			radiusport:'Please Input the port',
			attr:'Please Input the Attribute',
			username:'Please input username',
			remarks:'Please input remark',
			visitor_name:'Please input visitor name',
			visitor_telnum:'Please input visitor telnum',
			visitor_mail:'Please input visitor mail',
			visitor_company:'Please input visitor company',
			visitor_interviewee_name:'Please input interviewee name',
		}
	},
	client:{
		title:'Client information management',
		table:{
			add:'Add Client',
			nasid:'NASID',
			nasip:'NASIP',
            reqip:'Requry ip',
			psk:'PSK',
			base:'Authentication library',
			case:'Scene',
			authzone:'Authzone'
		},
		dialogs:{
			delete:'Confirm to delete information?',
		},
		btn:{
			add:'Add authentication',
			edit:'Edit authentication',
			delete:'Confirm deletion',
		},
		rule:{
			nasid:'NASID is required',
			psk:'PSK is required',
			base:'Authentication library required',
			case:'Scene is required',
			group:'Group is required',
			nasip:'Enter the correct IP address'
		},
		label:{
			nasid:'NASID',
			psk:'PSK',
			base:'Authentication library',
			case:'Scene',
			group:'Group',
		},
		placeholder:{
			nasid:'Input nasid',
			psk:'Input psk',
			nasip:'Input nasip(Leave blank to get IP automatically)',
			base:'Select authentication library',
			group:'Select a Group',
			case:'Select a place',
			authzone:'Select a authzone',	
            type:"Authentication library Type"
		},
		type:{
            ldap:"Ldap authentication library",
            local:"local authentication library"
        }
	},
	gatewayManagement: {
		pickPlaceName: 'Select a place',
		pickWg: 'Select a gateway',
		tableName: "Gateway administration",
		id: 'ID',
		sdk: 'SDK',
		pickSdk: 'Add SDK',
		add: 'Add gateway',
		shopName:'Place name',
		groupName: 'Group name',
		placeName: 'Place name',
		name: 'Gateway name',
		config: 'config',
		connection: '',
		up: 'Up',
		down: 'Down',
		d_up: 'Proxy up',
		d_down: 'Proxy down',
		count_flow: 'Cumulative flow',
		ten_flow: 'Ten minute flow',
		addGatewaySuccess: 'Add Gateway Success',
		editGatewaySuccess: 'Edit Gateway Success',
		delGatewaySuccess: 'Delete Gateway Success',
        inputFull: 'Please fill in the necessary options',
        smsgw_name: 'Gateway name'
	},
	apiManagement: {
		pickPlaceName: 'Select a place',
		pickWg: 'Select a gateway',
		tableName: "SMS platform API list",
		name: 'SMS platform name',
		sdkVersion: 'SDK Version',
		namespace: 'Namespace',
		action: 'Action',
		deleteSuccess: 'Delete Success',
		fileSuccess: 'Up Success',
		fileNull: 'File is Null',
	},
	sendingRecord: {
		pickPlaceName: 'Select a place',
		pickWg: 'Select a gateway',
		pickPhone: 'Matching Phone',
		like: 'Matching content',
		tableName: "SMS sending list",
		phone: 'phone',
		info: 'content',
		name: 'SMS platform name',
		sdkVersion: 'SDK Version',
		namespace: 'Namespace',
		time:'Time',
	},
	device:{
		title:'List of network security devices',
		selectTitle:'Select filter criteria',
		btn:{
			exports:'Export',
			inputs:'Import',
			edit:'Modify device information',
			delete:'Confirm deletion',
		},
		txt:{
			null:'0',
			type1:'Single audit device',
			type2:'Single sniffer device',
			type3:'Audit sniffer device',
			baseInfoStatus0:'Unreported',
			baseInfoStatus1:'Reporting',
			baseInfoStatus2:'Reported',
			big:'>0',
			status1:'Normal',
			status2:'Abnormal'
		},
		placeholder:{
			area:'Select area',
			company:'Select a group',
			place:'Select a place',
			name:'Select a name',
			type:'Select a type',
			status1:'Select report status',
			status2:'Select real identity status',
			status3:'Select virtual identity status',
			status4:'Select sniff status',
			status5:'Select uplink platform status',
			mac:'Input mac',
		},
		label:{
			pro:'Province',
			city:'City',
			area:'Area',
			company:'Group',
			place:'Place',
			name:'Name',
			mac:'Mac',
			type:'Type',
			status1:'Report status',
			status2:'Real identity status',
			status3:'Virtual identity status',
			status4:'Sniff status',
			status5:'Uplink platform status',
		},
		rule:{
			name:'Name is required',
			mac:'Mac is required',
			type:'Type is required',
			group:'Group is required',
			shop:'Shop is required'
		},
		table:{
			add:'Add device',
			id:'ID',
			pro:'Province',
			city:'City',
			area:'Area',
			company:'Group',
			place:'Place',
			name:'Name',
			type:'Type',
			status1:'Report status',
			status2:'Real identity status',
			status3:'Virtual identity status',
			status4:'Sniff status',
			status5:'Uplink platform status',
			role1:'Real identity',
			upTime:'On-line time',
			mac:'Mac',
			apmac:'Apmac',
			role2:'Virtual identity',
			roleType:'Virtual identity type',
		}
	},
	gateway:{
		implementOk:'Executed',
		implementCancel:'Unexecuted',
		statusUp:'On-line',
		statusDown:'Off-line',
		equipment:'Gateway device',
		equipment2:'Network safety equipment',
		title_waiting:"List of devices to be bound",
		title:'Device list',
		title_wait:'List of devices to be bound',
		title_instructions:'List of historical instruction details',
		selecTitle:'Select device list criteria',
		table:{
			pro:'Province',
			city:'City',
			area:'Area',
			company:'Group',
			place:'Place',
			name:'Name',
			status:'Status',
			license:'To grant authorization',
			deviceId:'Serial number',
			os:'System version',
			uptime:'Turn on time',
			version:'Gateway version',
			instructions:'Instructions',
			mac:'Mac',
		},
		tableExpand:{
			powerOk:'Authorized',
			powerCancel:'Unauthorized',
			progress:'Progress',
			msg:'Information',
			abnormal:'Abnormal information',
			memory:'Memory ratio',
			cpu:'Usage situation',
			disk:'Disk mount information',
			diskname:'Disk name',
			diskposition:'Position',
			disktype:'Type',
			availableSpace:'Available space',
			useSpace:'Use space',
			bandwidth:'Bandwidth',
			bandname:'Interface name',
			bandpocketS:'Sending packets',
			bandnumberS:'Bytes sent',
			bandpocketR:'Number of packets received',
			bandnumberR:'Bytes received',
			listenPort:'Monitor port information',
			listenProgress:'Process information',
			listenPath:'Process path',
			listenName:'Process file name',
			listenCmds:'Process parameters',
			listenType:'Listening port type',
			listenPortN:'Monitor port',
			dhcpLeases:'List of assigned clients',
			dhcpName:'Name',
			dhcpTime:'Time',
			dhcpInterface:'Interface state',
			command:'Command',
			pid:'Pid',
		},
		label:{
			areaPro:'Province',
			areaCity:'City',
			areaArea: 'Area',
			issue: 'issue',
			company:'Group',
			place:'Place',
			name:'Name',
			type:'Type',
			status:'Status',
			mac:'Mac',
		},
		rule:{
			name:'Name is required',
			gateway:'The number of gateways is required',
			ins:'Instructions is required',
			device:'Devices is required',
		},
		btn:{
			add_ins:'Add instruction',
			add: 'Add gateway device',
			issue: 'Issue instruction',
			exports:'Export',
			inputs:'Import',
			editTheme:'Bind',
			edit:'Modify',
			delete:'Confirm deletion',
			join:'Join',
			login:'Remote login',
			look:'View details',
			remote:'Add remote',
			used:'Implement',
			go:'Gateway',
            showmwan: 'Show mwan',
            mwantop: 'Mwan Top',
            allman: 'Show Device Mwan',
            mainlog: 'Main Net Log',
		},
		placeholder:{
			areaPro:'Select province',
			areaCity:'Select city',
			areaArea:'Select area',
			company:'Input Group',
			place:'Input place',
			name:'Input name',
			type:'Input Type',
			status:'Input status',
            inputname:'Input name',
			mac:'Input Mac',
            ip:'Input Ip'
		},
		instructions:{
			ins:'Instruction',
			name:'Instruction name',
			placeholder:'Input instruction',
			equipment:'Gateway device',
			status:'Status',
			time:'Time',
			step1:'Name of step 1',
			step2:'Name of step 2',
			step3:'Name of step 3',
			pre:'Previous',
			next:'Next',
		}
	},
	bigScreen: {
		title: '云平台大屏',
		left1: '网关在线率',
		left2: '每日认证图',
		left3: '用户在线率',
		left4: '应用连接数TOP',
		right1: '网安在线率',
		right2: '上行流量趋势',
		right3: '下行流量趋势',
		chartTitle2_1: '连接趋势',
		firstTitle1: '网关在线/总数',
		firstTitle2: '连接数 TCP/UDP/IMCP',
		firstTitle3: '在线/今日累计用户',
		firstTitle4: '网安在线/总数',
	},
	bigScreen3: {
		topLeftTitle1: '实时网关在线率',
		topLeftTitle2: '实时网安在线率',
		online: '在线',
		outline: '离线',
		topMiddleTitle: '连接趋势图',
    },
    userflow:{
        device_name: '设备名称',
        mac: 'mac地址',
        in_flow: '下行总流量',
        out_flow: '上行总流量',
        flowcnt: '连接数',
        inrate: '平均下行速率',
        outrate: '平均上行速率',
        onlinesec: '在线时间',
    },
	license:{
		title:"License管理",
		upload:"上传lcs文件",
		download:"下载lcr文件",
		cancel:"取消授权",
		serialNum:"序列号",
		expireDate:"到期时间",
		status:{
			title:"状态",
			true:"已授权",
			false:"未授权"
		},
		fail:"请上传lcs文件",
		error:"上传失败",
        dialogText:'是否确认取消授权？'
	},
	email:{
		table:{
			title:"邮箱列表",
			name:"名称",
			host:"SMTP服务器地址",
            port:"端口",
			username:"邮箱地址",
			password:"密码",
			group:"集团",
			shop:"场所"
		},
        placeholder:{
            host:"SMTP服务器地址（示例：sina.smtp.com）",
            port:"端口（SMTP服务的默认端口为25）",
        },
		btn:{
			add:"添加邮箱",
			delete:"删除邮箱",
			edit:"修改邮箱信息",
		},
		rules:{
			name:"名称不能为空",
			host:"SMTP服务器地址不能为空",
            port:"端口号格式错误",
			username:"邮箱格式错误",
			password:"密码不能为空",
			group:"集团不能为空",
			shop:"场所不能为空"
		}
		
	},
	alert:{
		btn:{
			add:'添加告警规则',
            bindGateway:'绑定网关',
            save:'保存',
            del:'删除'
		},
        alertRecord:"告警记录",
        table:{
            name:'规则名称',
            active:'启用',
            inactive:'停用',
            from_name:'发件人姓名',
            editing:'编辑中...',
            isActive:'已启用',
            notActive:'已停用',
            time:'记录时间'
        },
        form:{
            fromName:'发件人姓名',
            title:'邮件标题',
            content:'邮件内容',
            email:'发送者邮箱',
            addresses:'收件人',
            ccs:'抄送人',
        },
        tip:{
            shopEmpty:'如果场所为空则该规则对所有场所用户不可见',
            addAddress:'按回车键进行添加'
        },
        rules:{
            name:'规则名称不能为空',
            title:'邮件标题不能为空',
            content:'邮件内容不能为空',
            email:'邮件服务器不能为空',
            group:'集团不能为空',
            address:'收件人不能为空',
            fromName:'发件人姓名不能为空',
            eamilFormatErr:'邮箱格式错误'
        },
        deleteTitle:'删除规则'
	},
    ldap:{
        table:{
            title:"Ldap列表",
            editTitle:"Ldap配置编辑",
            addTitle:"添加Ldap配置",
            name:"名称",
            host:"Ldap服务器地址",
            port:"端口",
            admin_dn:"管理员账号",
            admin_pwd:"管理员密码"
        },
        btn:{
            add:"添加Ldap配置"
        },
        rules:{
            admin_dn:"管理员账号不能为空",
            admin_pwd:"管理员密码不能为空",
            base_dn:"base_dn不能为空",
            filter:"filter不能为空"
        }
    },
	radacctStatistic:{
		selectTitle: "Query criteria ",
		jt: 'Group',
		pickjt: 'Select a group',
		placeName: 'Place name',
		pick_placename: 'Select a place',
		pickDateRange: 'Select time period',

		tableTitle: 'Shop Radacct',
		exportBtn:'Export',
		
		editBtn: 'Edit',
		deleteBtn: 'Delete',
		searchBtn: 'Search',
		close: 'Close',
		confirm: 'Confirm',
		
	},
	radacctRule:{
		title:'Radacct Policy Group',
		searchBtn: 'Search',
		close: 'Cloase',
		confirm: 'Confirm',
		save: 'Save',
		update: 'Update',
		delTooltip: 'Are you confirm to delete this data?',
		pickMonthRange:'Select month period',

		warning: 'Request failure',
    	success: 'Operation successful',

		btn:{
			addBtn:'Add Policy Group',
			editBtn: 'Edit',
			deleteBtn: 'Delete',
		},

		table:{
			id:'Id',
			name:'Name',
			action:'Action',
			rule: 'Rule',

		},
	},
}