<template>
	<div id="silder">
		<v-navigation-drawer id="app-drawer" v-model="inputValue" app dark floating persistent permanent mobile-breakpoint="991" width="260" :mini-variant.sync="mini">
			<v-img :src="image" height="100%">
				<v-layout class="fill-height" tag="v-list" column style="background: rgba(27, 27, 27, 0.74);">
					<v-list-item class="bordor-radius4" style="max-height:69px">
						<v-list-item-title style="font-weight: 500;text-align:center">
							<!-- {{$vuetify.lang.t('$vuetify.common.project')}} -->
                            {{groupName+"集中管理平台"}}
						</v-list-item-title>
					</v-list-item>
					<v-divider style="margin-bottom: 10px;margin: 0 15px 0 15px;" />
                    <v-container
                        id="scroll-target"
                        style="padding:0"
                        class="overflow-y-auto">
						<div id="permission-draw">
							<v-list nav dense style="background: rgba(0, 0, 0, 0);">
								<template v-for="menu in menus">
									<v-list-group 
										v-if="menu.child && showMenu(menu.v_name)"
										:key="menu.id" 
										:prepend-icon="menu.icon" 
										no-action 
										:append-icon="menu.child ? undefined : ''" style="color: #fff!important;">
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title class="font-weight-light" style="font-size: 14px;" v-if="language !='en'">{{ menu.zh_name }}</v-list-item-title>
												<v-list-item-title class="font-weight-light" style="font-size: 14px;" v-if="language =='en'">{{ menu.en_name }}</v-list-item-title>
											</v-list-item-content>
										</template>
										<v-list-item 
											v-for="subMenu in menu.child"
											v-if="showMenu(menu.v_name,subMenu.v_name)" 
											:key="subMenu.id" 
											link 
											:to="subMenu.name" 
											:target="subMenu.name=='/monitor'|| subMenu.name=='/bg3'? '_blank' : '' " 
											:active-class="color">
											<v-list-item-icon>
												<v-icon>{{ subMenu.icon }}</v-icon>
											</v-list-item-icon>
											<v-list-item-title class="font-weight-light" v-if="language !='en'"> {{ subMenu.zh_name }}</v-list-item-title>
											<v-list-item-title class="font-weight-light" v-if="language =='en'"> {{ subMenu.en_name }}</v-list-item-title>
										</v-list-item>
									</v-list-group>
									<v-list-item 
										v-else-if="showMenu(menu.v_name)"
										:key="menu.name"  
										link :to="menu.name" 
										:active-class="color">
										<v-list-item-action>
											<v-icon>{{ menu.icon }}</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title v-if="language !='en'">
												{{ menu.zh_name }}
											</v-list-item-title>
											<v-list-item-title v-if="language =='en'">
												{{ menu.en_name }}
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-list>
						</div>
                    </v-container>
				</v-layout>
			</v-img>
		</v-navigation-drawer>
	</div>
</template>
<script>
	import {
		mapMutations,
		mapState
	} from 'vuex'
	import * as api from '../../api'
	export default {
		props: {
			opened: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			type:0,
			systemType:0,
            groupName:(window.sessionStorage.getItem('groupName')!="" && window.sessionStorage.getItem('groupName')!="undefined") ? window.sessionStorage.getItem('groupName') :"奕通安全网关",
			menus: [
				{
					zh_name: '仪表盘',
					en_name: 'Dashboard',
					icon: 'mdi-view-dashboard',
					name: '/dashboard',
					v_name:"dashboard",
				}, {
					zh_name: '用户管理',
					en_name: 'User',
					icon: 'mdi-account-cog',
					v_name:"user",
					child: [{
						zh_name: '集团管理',//场所用户不显示本菜单
						en_name: 'Group Management',
						icon: 'mdi-square-small',
						name: '/user/bloc',
						v_name:"bloc",
					},{ 
						zh_name: '场所管理',
						en_name: 'Place Management',
						icon: 'mdi-square-small',
						name: '/user/site',
						v_name:"site",
					},{
						zh_name: '用户管理', //场所用户不显示本菜单
						en_name: 'User Management',
						icon: 'mdi-square-small',
						name: '/user/user',
						v_name:"user",
					},{
						zh_name: '角色管理',//场所用户不显示本菜单
						en_name: 'Role Management',
						icon: 'mdi-square-small',
						name: '/user/role',
						v_name:"role",
					}]
				},  {
					zh_name: '网关管理',
					en_name: 'Gateway',
					icon: 'mdi-server-network',
					v_name:"gateway",
					child: [
					// {
					// 	zh_name: '待绑定设备列表',
					// 	en_name: 'Waiting Device',
					// 	icon: 'mdi-square-small',
					// 	name: '/gateway/waiting_device',
					// },
					{ 
						zh_name: '设备列表',
						en_name: 'Devices',
						icon: 'mdi-square-small',
						name: '/gateway/devices',
						v_name:"devices",
					},
					{
						zh_name:'邮箱管理',
						en_name:'Email Management',
						icon:'mdi-square-small',
						name:'/gateway/email',
						v_name:"email",
					},
					{
						zh_name:'告警管理',
						en_name:'Alert Management',
						icon:"mdi-square-small",
						name:'/gateway/alert',
						v_name:"alert",
					},
                    {
						zh_name:'告警记录',
						en_name:'Alert Record',
						icon:"mdi-square-small",
						name:'/gateway/alert_record',
						v_name:"alert_record",
					},
					// { 
					// 	zh_name: '远程指令',
					// 	en_name: 'Remote Order',
					// 	icon: 'mdi-square-small',
					// 	name: '/gateway/remote_order',
					// }
					]
				}, {
					zh_name: 'AP设备管理',
					en_name: 'Device Management',
					icon: 'mdi-devices',
					v_name:"device",
					child: [
					{
						zh_name: 'AP管理',
						en_name: 'Ap Manage',
						icon: 'mdi-square-small',
						name: '/device/ap',
						v_name:"ap",
					}
					]
				}, {
					zh_name: 'Portal管理',
					en_name: 'Portal',
					icon: 'mdi-blur-linear',
					v_name:"portal",
					child: [{
						zh_name: '认证域',
						en_name: 'Authentication Domain',
						icon: 'mdi-square-small',
						name: '/portal/ac',
						v_name:"ac",
					},{ 
						zh_name: '用户模板',
						en_name: 'User Template',
						icon: 'mdi-square-small',
						name: '/portal/udemo',
						v_name:"udemo",
					},{ 
						zh_name: '系统模板',//仅超管可见
						en_name: 'System Template',
						icon: 'mdi-square-small',
						name: '/portal/sdemo',
						v_name:"sdemo",
					},{ 
						zh_name: '模板类型',
						en_name: 'Template Type',
						icon: 'mdi-square-small',
						name: '/portal/templatetype',
						v_name:"templatetype",
					},{ 
						zh_name: '模板策略组',
						en_name: 'Scheduler',
						icon: 'mdi-square-small',
						name: '/portal/scheduler',
						v_name:"scheduler",
					}]
				}, {
					zh_name: 'AAA管理',
					en_name: 'AAA',
					icon: 'mdi mdi-database',
					v_name:"AAA",
					child: [
					// {
					// 	zh_name: '服务端管理', //仅超管可见
					// 	en_name: 'Server Management',
					// 	icon: 'mdi-square-small',
					// 	name: '/AAA/server_management',
					// },
					{ 
						zh_name: '客户端管理',
						en_name: 'Client Management',
						icon: 'mdi-square-small',
						name: '/AAA/client_management',
						v_name:"client_management",
					},{ 
						zh_name: '认证库管理',
						en_name: 'Auth Library Management',
						icon: 'mdi-square-small',
						name: '/AAA/auth_management',
						v_name:"auth_management",
					},{
                        zh_name:'Ldap管理',
                        en_name:'Ldap Management',
                        icon: 'mdi-square-small',
						name: '/AAA/ldap_management',
						v_name:"ldap_management",
                    }]
				},
				// {
				// 	zh_name: '网安管理',
				// 	en_name: 'Net Safety',
				// 	icon: 'mdi-warehouse',
				// 	child: [{
				// 		zh_name: '网安设备列表',
				// 		en_name: 'Network Safety Device',
				// 		icon: 'mdi-square-small',
				// 		name: '/net_safety/device',
				// 	}]
				// },
			 	{
					zh_name: '计费管理',
					en_name: 'Radacct',
					icon: 'mdi mdi-cash-usd',
					v_name: "radacct",
					child: [
						{
							zh_name: '计费统计',
							en_name: 'Radacct Statistics',
							icon: 'mdi-square-small',
							name: '/radacct/radacct_statistics',
							v_name: "radacct_statistics",
						}, 
                        {
							zh_name: '计费策略组',
							en_name: 'Radacct Schedule',
							icon: 'mdi-square-small',
							name: '/radacct/radacct_rule',
							v_name: "radacct_rule",
						},
					]
				},

				{
					zh_name: '短信管理',
					en_name: 'SMS',
					icon: 'mdi-message-minus',
					v_name:"sms",
					child: [{
						zh_name: '接口管理', //仅超管可见
						en_name: 'Api Management',
						icon: 'mdi-square-small',
						name: '/sms/api_management',
						v_name:"api_management",
					},{ 
						zh_name: '网关管理',
						en_name: 'Gateway Management',
						icon: 'mdi-square-small',
						name: '/sms/gateway_management',
						v_name:"gateway_management",
					},{ 
						zh_name: '短信发送记录',
						en_name: 'SMS Sending Record',
						icon: 'mdi-square-small',
						name: '/sms/sending_record',
						v_name:"sending_record",
					}]
				}, {
					zh_name: '数据管理',
					en_name: 'Data Management',
					icon: 'mdi-database-edit',
					v_name:"data",
					child: [
					// 	{
					// 	zh_name: '大屏1',
					// 	en_name: 'Big Screen',
					// 	icon: 'mdi-square-small',
					// 	name: '/monitor',
					// },{
					// 	zh_name: '大屏2',
					// 	en_name: 'Big Screen',
					// 	icon: 'mdi-square-small',
					// 	name: '/bg3',
					// },
					{ 
						zh_name: 'Web认证记录',
						en_name: 'Auth Record',
						icon: 'mdi-square-small',
						name: '/data/auth_record',
						v_name:"auth_record",
					},
					{ 
						zh_name: '用户流量预警记录',
						en_name: 'Client Bytes Record',
						icon: 'mdi-square-small',
						name: '/data/client_record',
						v_name:"client_record",
					},
					{ 
						zh_name: 'Top统计',
						en_name: 'Top Statistics',
						icon: 'mdi-square-small',
						name: '/data/top_record',
						v_name:"top_record",
					},{ 
						zh_name: '认证统计',
						en_name: 'Auth Statistics',
						icon: 'mdi-square-small',
						name: '/portal/statistics',
						v_name:"statistics",
					},
					{ 
						zh_name: 'Radius认证记录',
						en_name: 'Radius Record',
						icon: 'mdi-square-small',
						name: '/data/radius_record',
						v_name:"radius_record",
					},
					// { 
					// 	zh_name: '带宽记录',
					// 	en_name: 'Bindwidth record',
					// 	icon: 'mdi-square-small',
					// 	name: '/data/bindwidth_record',
					// },{ 
					// 	zh_name: '应用识别',
					// 	en_name: 'App Identification',
					// 	icon: 'mdi-square-small',
					// 	name: '/data/app_identification',
					// },{ 
					// 	zh_name: '用户流量',
					// 	en_name: 'User Flow',
					// 	icon: 'mdi-square-small',
					// 	name: '/data/user_flow',
					// },
					]
				},{
					zh_name: '系统管理',
					en_name: 'System Management',
					icon: 'mdi-cogs',
					v_name:"system",
					child: [{
						zh_name: '日志列表',
						en_name: 'Syslog',
						icon: 'mdi-square-small',
						name: '/system/syslog',
						v_name:"syslog",
					},{ 
						zh_name: '权限列表', //仅超管可见
						en_name: 'Permission',
						icon: 'mdi-square-small',
						name: '/system/permission',
						v_name:"permission",
					},{ 
						zh_name: '地区管理', //仅超管可见
						en_name: 'Place Management',
						icon: 'mdi-square-small',
						name: '/system/place_management',
						v_name:"place_management",
					},{ 
						zh_name: '系统配置', //仅超管可见
						en_name: 'System Config',
						icon: 'mdi-square-small',
						name: '/system/config',
						v_name:"config",
					}]
				}
			],
			menulist:{},
			mini: false,
			language:localStorage.getItem('language')=='en'?'en':'zh',
		}),
		computed: {
			...mapState('app', ['image', 'color']),
			inputValue: {
				get() {
					return this.$store.state.app.drawer
				},
				set(val) {
					this.setDrawer(val)
				}
			},
		},
		mounted(){
			this.$nextTick(()=>{
				this.type = window.sessionStorage.getItem('type');
				this.systemType =  window.sessionStorage.getItem('systemType');
				if(this.systemType==1){
					this.menulist = {
						"dashboard":{},
						"device":{
							"ap":true,
						},
						"portal":{
							"ac":true,
							"udemo":true,
							"scheduler":true,
						},
						"AAA":{
							"client_management":true,
							"auth_management":true,
						},
						"radacct": { 
							"radacct_statistics": true,
							"radacct_rule": true,
						},
						"sms":{
							"api_management":true,
							"gateway_management":true,
							"sending_record":true,
						},
						"data":{
							"auth_record":true,
							"radius_record":true,
						},
						"system":{
							"syslog":true,
							"permission":true,
							"place_management":true,
							"config":true,
						},
					}
				}else{
					this.$http.get(api.default.auth.menuitemUrl).then(resp => {
						this.menulist = resp.data
					})
				}
			})
		},
		methods: {
			...mapMutations('app', ['setDrawer', 'toggleDrawer']),

			showMenu(menu,menuItem) {
				if ( this.type == 0 &&  this.systemType != 1)
				{
					return true;
				}
				if(this.type == 1 && menu=="user" &&menuItem == "bloc"){
					return false;
				}
				if(this.type == 2 && menu=="user"){
					return false;
				}
				if( this.menulist[menu] != null){
					if(menuItem == null){
						return true;
					}
					var menuItemlist = this.menulist[menu]
					if(menuItemlist[menuItem]){
						return true;
					}
				}
				return false;
			},
		}
	}
</script>

<style lang="scss">
	#silder {
		.v-divider {
			margin: 0;
		}
		.v-list-item__title {
			padding: 0;
			height: 24px;
			line-height: 24px;
		}
		.v-list-group__header {
			height: 56px;
			max-height: 56px;
			font-weight: 300!important;
			font-size: 16px;
		}
		.v-list-group__header__prepend-icon {
			margin: 12px 32px 16px 0!important;
		}
		// .v-list-group__items {
		// 	margin-bottom: 5px!important;
		// }
		.v-list-item {
			padding: 2px 16px!important;
		}
		.v-list-item--link {
			max-height: 56px;
			line-height: 56px;
		}
		.v-navigation-drawer .v-list .v-list-item:nth-child(1) {
			margin-bottom: 5px!important;
		}
		.v-navigation-drawer .v-list .v-list-item:not(:nth-child(3)) {
			margin: 0 15px 0;
		}
		.bordor-radius4 {
			border-radius: 4px;
			margin-bottom: 0!important;
		}
		.drawer_backgroup {
			background: rgba(27, 27, 27, 0.74);
		}
	}
	
	#app-drawer {
		.v-list__tile {
			border-radius: 4px;
			&--buy {
				margin-top: auto;
				margin-bottom: 17px;
			}
		}
	}
</style>
