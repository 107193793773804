import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFoundRouter from '@/router/notfound.js'

Vue.use(VueRouter)

//下面添加路由[NotFoundRouter,....]
const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/Auth/Login.vue')
  },
  {
    path: '/',
    meta: {requiresAuth: true},
    redirect: '/dashboard',
  },
  {
    path: '/Editlogin',
    name: 'Editlogin',
    component: () => import(/* webpackChunkName: "Editlogin" */ '@/views/EditConfig/Editlogin.vue')
  },
  {
    path: '/ConfigInfo',
    name: 'ConfigInfo',
    meta: {requiresConfigAuth: true},
    component: () => import(/* webpackChunkName: "ConfigInfo" */ '@/views/EditConfig/ConfigInfo.vue')
  },
  {
    path: '/monitor',
    name: '大屏|Monitor',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/BigScreen.vue')
  },{
    path: '/',
    meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "Index" */ '@/components/core/View.vue'),
    children: [{
        path: '/dashboard',
        name: '仪表盘|Dashboard',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Dashboard/Index.vue')
      },
      //用户管理
      {
        path: '/user/bloc',
        name: '集团管理|Group Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Rbac/UserBloc.vue')
      },{
        path: '/user/site',
        name: '场所管理|Place Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Rbac/UserSite.vue')
      },{
        path: '/user/user',
        name: '用户管理|User',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Rbac/UserUser.vue')
      },{
        path: '/user/role',
        name: '角色管理|Role Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Rbac/UserRole.vue')
      },
    //网关管理
      {
        path: '/gateway/waiting_device',
        name: '待绑定设备列表|Waiting Device',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/WaitingDevice.vue')
      },{
        path: '/gateway/devices',
        name: '设备列表|Devices',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/Devices.vue')
      },{
        path: '/gateway/devices_list',
        name: '设备详情|Device details',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/DeviceList.vue')
      },{
        path: '/gateway/remote_order',
        name: '远程指令|Remote Order',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/RemoteOrder.vue')
      },{
        path: '/gateway/instructions_info',
        name: '指令详情|Remote Order info',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/Instructions.vue')
      },{
        path: '/gateway/instructions_add',
        name: '添加指令|Add Remote Order',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/InstructionsAdd.vue')
      },{
        path:'/gateway/email',
        name:'邮箱管理|Email Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/Email.vue')
      },{
        path:'/gateway/alert',
        name:'告警管理|Alert Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/Alert.vue')
      },{
        path:'/gateway/alert_record',
        name:'告警记录|Alert Record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Gateway/AlertRecord.vue')
      },
      // 设备管理
      {
        path: '/device/ap',
        name: 'Ap管理|Ap Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Device/Ap.vue')
      },
      //portal管理
      {
        path: '/portal/ac',
        name: '认证域|Authentication Domain',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/Ac.vue')
      },{
        path: '/portal/scheduler',
        name: '模板策略组|Scheduler',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/Scheduler.vue')
      },{
        path: '/portal/udemo',
        name: '用户模板|User Template',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/Udemo.vue')
      },{
        path: '/portal/sdemo',
        name: '系统模板|System Template',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/Sdemo.vue')
      },{
        path: '/portal/templatetype',
        name: '模板类型|Template Type',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/TemplateType.vue')
      },
      //aaa管理
      {
        path: '/AAA/server_management',
        name: '服务端管理|Server Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Aaa/Server.vue')
      },{
        path: '/AAA/client_management',
        name: '客户端管理|Client Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Aaa/Client.vue')
      },{
        path: '/AAA/auth_management',
        name: '认证库管理|Auth Library Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Aaa/Auth.vue')
      },{
        path:'/AAA/ldap_management',
        name:'Ldap管理|Ldap Management',
        meta:{requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Aaa/Ldap.vue')
      },
      //网安管理
      {
        path: '/net_safety/device',
        name: '网安设备列表|Network Safety Device',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/NetSafety/Device.vue')
      },
      {
        path: '/net_safety/device_list',
        name: '网安设备详细列表|Network Safety Device List',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/NetSafety/DeviceList.vue')
      },
      //短信管理
      {
        path: '/sms/api_management',
        name: '接口管理|Api Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/SMS/ApiManagement.vue')
      },{
        path: '/sms/gateway_management',
        name: '网关管理|Gateway',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/SMS/GatewayManagement.vue')
      },{
        path: '/sms/sending_record',
        name: '短信发送记录|SMS Sending Record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/SMS/SendingRecord.vue')
      },
      //数据管理
      {
        path: '/data/auth_record',
        name: 'Web认证记录|Auth Record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/PortalMacAuth.vue')
      },{
        path: '/data/client_record',
        name: '用户流量预警记录|Client Bytes Record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/ClientRecord/ClientRecord.vue')
      },{
        path: '/data/top_record',
        name: 'Top统计|Top Statistics',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/TopRecord/TopRecord.vue')
      },
      {
        path: '/portal/statistics',
        name: '认证统计|Auth Statistics',
        meta: { requiresAuth: true },
        // component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/Statistics.vue')
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Portal/Statistics.vue')
      },{
        path: '/data/radius_record',
        name: 'Radius认证记录|Radius Record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/RadiusRecord.vue')
      },{
        path: '/data/auth_online',
        name: '在线记录|Auth online',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/AuthOnline.vue')
      },{
        path: '/data/bindwidth_record',
        name: '带宽记录|Bindwidth record',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/BindwidthRecord.vue')
      },{
        path: '/data/app_identification',
        name: '应用识别|App Identification',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/AppIdentification.vue')
      },{
        path: '/data/auth_record/:id',
        name: '用户画像|User model',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/Personas.vue')
      },{
        path: '/data/user_flow',
        name: '用户流量|User Flow',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Data/UserFlow.vue')
      },
      //系统管理
      {
        path: '/system/syslog',
        name: '系统日志|System log',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/System/Syslog.vue')
      },{
        path: '/system/permission',
        name: '权限列表|Permission',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/System/Permission.vue')
      },{
        path: '/system/place_management',
        name: '地区管理|Place Management',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/System/PlaceManagement.vue')
      },{
        path: '/system/config',
        name: '系统配置|System Config',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/System/Config.vue')
      },
       //计费管理
      {
        path: '/radacct/radacct_statistics',
        name: '计费统计|Radacct Statistics',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Radacct/Statistics.vue')
      },{
        path: '/radacct/radacct_rule',
        name: '计费规则|Radacct Rule',
        meta: {requiresAuth: true},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/Radacct/RadacctRule.vue')
      },
    ],
  },
  NotFoundRouter]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    to.matched.some(r => {
        // 模块需要认证后使用
        if (r.meta.requiresAuth) {
            let authUser = window.sessionStorage.getItem('authUser')
            let userType = window.sessionStorage.getItem('type')

            // 未认证，或认证数据有问题，则跳转到login
            if (!authUser || !userType) {
                next({ name: 'Login' })
                return
            }
        }

        if (r.meta.requiresConfigAuth) {
          let name = window.sessionStorage.getItem('name')
          let userType = window.sessionStorage.getItem('type')

          // 未认证，或认证数据有问题，则跳转到Editlogin
          if (name!="config" || !userType) {
              next({ name: 'Editlogin' })
              return
          }
      }
    })
    next()
})

export default router
